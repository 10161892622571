<!-- @file-编辑用户实例 -->
<template>
  <div>
    <l-search>
      <l-form layout="inline" laba-width="70px">
        <l-form-item label="系统实例">
          <l-select
            v-model="appInstanceId"
            filterable
            remote
            reserve-keyword
            :remote-method="remoteMethod"
            :loading="loading"
            placeholder="请选择系统实例"
            :disabled="!!submitData.appInstanceId"
          >
            <l-select-option v-for="item in appInstanceList" :key="item.id" :value="item.id">{{ item.name }}</l-select-option>
          </l-select>
        </l-form-item>
      </l-form>
      <div slot="right">
        <l-button class="l-float-right" @click="resetForm">重置条件</l-button>
      </div>
    </l-search>
    <div>
      <div v-if="appInstanceId" class="l-text-right l-mb-10">
        <div class="form-group">
          <div>
            <l-button
              v-show="submitData.isAdmin === 0"
              type="primary"
              class="l-mr-10"
              @click="globalAllResource"
            >
              所有权限
            </l-button>
            <l-button
              v-show="submitData.isAdmin === 0"
              type="plain"
              @click="globalReadResource"
            >
              所有只读
            </l-button>
            <span style="margin:0 10px;">是否拥有所有菜单:</span>
            <l-radio-group v-model="submitData.isAdmin">
              <l-radio :value="1">是</l-radio>
              <l-radio :value="0">否</l-radio>
            </l-radio-group>
            <l-button
              :loading="isSave"
              type="primary"
              @click="save"
            >
              保存实例
            </l-button>
            <l-button
              v-show="!!$utils.getParam('appUserInstanceId') && submitData.isAdmin === 0"
              :loading="isResetInstance"
              class="l-ml-10"
              @click="resetInstance"
            >
              重置实例
            </l-button>
          </div>
        </div>
      </div>
      <div v-show="submitData.isAdmin === 0" class="c-menu-tree">
        <l-tree
          v-if="isInit"
          ref="tree"
          :checked-keys="defaultCheckedKeys"
          :tree-data="treeData"
          :replace-fields="{children:'children', title:'name', key:'id'}"
          :default-expand-all="true"
          :block-node="true"
          :default-checked-keys="defaultCheckedKeys"
          checkable
          @check="checkChange"
        />
      </div>
      <!-- 当前选择的菜单资源树 -->
      <div v-show="submitData.isAdmin === 0" class="c-menu-tree_setting">
        <l-tree
          :tree-data="treeSelectData"
          :replace-fields="{children:'children', title:'name', key:'id'}"
          :default-expand-all="true"
          :block-node="true"
          @select="nodeClick"
        >
          <span
            slot="slotsTitle"
            slot-scope="{ dataRef }"
            class="custom-tree-node"
          >
            <span>{{ dataRef.name }}</span>
            <span v-if="dataRef.resources.length === 0 || dataRef.level === 1" style="font-size:12px;">(无需设置)</span>
            <span v-else-if="dataRef.menuResources.length === dataRef.resources.length" style="font-size:12px;" class="deep-green">(所有权限)</span>
            <span v-else-if="dataRef.menuResources.length === 0" style="font-size:12px;" class="red">(无权限)</span>
            <span v-else style="font-size:12px;" class="green">(部分权限)</span>
          </span>
        </l-tree>
      </div>

      <!-- 资源关联 -->
      <div
        v-show="submitData.isAdmin === 0 && menu && Object.keys(menu).length > 0 && menu.resources.length > 0"
        style="display:inline-block;width:49%;vertical-align:top;"
        class="l-ml-20"
      >
        <div style="margin-bottom:10px;">
          <l-button type="primary" size="small" @click="allResource">所有</l-button>
          <l-button
            type="plain"
            size="small"
            class="l-ml-10"
            @click="readResource"
          >
            只读
          </l-button>
        </div>
        <l-transfer
          :target-keys="menu.menuResources"
          :data-source="menuResources"
          :titles="['全部权限', '所选权限']"
          :render="item => item.name"
          @change="handleResourcesChange(menu, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthAppUserInstanceEdit',
  data() {
    return {
      id: '',
      appUserInstanceId: null,
      searchFilter: {},
      appInstanceId: null,
      loading: false,
      flatDeepTreeData: [],
      treeData: [],
      treeSelectData: [],
      checkMenuTreeData: [],

      menuResource: {},
      menuResources: [],
      isSave: false,
      isResetInstance: false,
      menu: {},
      submitData: {
        isAdmin: 0,
        userId: 0,
        id: 0,
        appInstanceId: 0,
        menuIds: []
      },
      isInit: false,
      defaultCheckedKeys: [] // 默认选中节点
    }
  },
  computed: {
    ...mapState({
      appInstanceList: state => state.auth.authAppInstanceList
    })
  },
  watch: {
    appInstanceId: {
      handler() {
        if (!this.appInstanceId) {
          return
        }
        this.resetData()
      },
      immediate: true
    }
  },
  mounted() {
    this.id = this.$utils.getParam('userId')
    this.appUserInstanceId = this.$utils.getParam('appUserInstanceId')
    if (!this.id) {
      // this.$close(true)
      return this.$toPage('/auth-user/list')
    }
    this.resetForm()

    if (this.appUserInstanceId) {
      this.authInstanceMenu()
    }
  },
  activated() {
    this.id = this.$utils.getParam('userId')
    this.appUserInstanceId = this.$utils.getParam('appUserInstanceId')
    if (!this.id) {
      // this.$close(true)
      return this.$toPage('/auth-user/list')
    }
    this.resetForm()
    if (this.appUserInstanceId) {
      this.authInstanceMenu()
    } else {
      this.appUserInstanceId = null
      this.appInstanceId = null
      this.submitData = this.$options.data().submitData
      this.treeData = []
      this.treeSelectData = []
    }
  },
  methods: {
    handleResourcesChange(menu, targetKeys) {
      menu.menuResources = targetKeys
    },
    // 获取用户实例的菜单
    async authInstanceMenu() {
      const data = await this.$store.dispatch('auth/authInstanceMenu', {
        id: this.appUserInstanceId
      })
      this.appInstanceId = data.appInstanceId
      this.submitData = data

      this.submitData.menuList = this.submitData.menuList || []
      this.submitData.menuList.forEach(item => {
        item.oldResources = item.resources
      })
    },
    async remoteMethod(query) {
      this.loading = true
      await this.$store.dispatch('auth/authAppInstanceList', {
        quickSearch: query
      })
      this.loading = false
    },
    globalAllResource() {
      this.treeSelectData.forEach(item1 => {
        item1.children.forEach(item2 => {
          item2.children.forEach(item3 => {
            item3.menuResources = JSON.parse(JSON.stringify(item3.resources))
          })
        })
      })
    },
    globalReadResource() {
      this.treeSelectData.forEach(item1 => {
        item1.children.forEach(item2 => {
          item2.children.forEach(item3 => {
            item3.menuResources = []
            item3.resources.forEach(resourceId => {
              const r = this.menuResource[resourceId]
              if (r && r.type !== 2) {
                item3.menuResources.push(resourceId)
              }
            })
          })
        })
      })
    },
    readResource() {
      if (!this.menu) return
      this.menu.menuResources = []
      this.menuResources.forEach(item => {
        if (item.type !== 2) {
          this.menu.menuResources.push(item.id)
        }
      })
    },
    allResource() {
      if (!this.menu) return
      this.menu.menuResources = []
      this.menuResources.forEach(item => {
        this.menu.menuResources.push(item.id)
      })
    },
    nodeClick(keyList) {
      if (keyList.length !== 1) return
      let menu = this.checkMenuTreeData.find(menu => menu.id === keyList[0])
      if (!menu) return
      if (menu.id === -1) {
        menu = {}
      }
      this.menu = menu
      this.menuResources = []
      this.menu.resources.forEach(item => {
        this.menuResources.push(this.menuResource[item])
      })
    },
    checkMenuParentIdHandle(childrenMenuIdList) {
      const parentIdSet = new Set()
      let isErrorCount = 0
      childrenMenuIdList.forEach(item => {
        let menuId = item
        let findMenu
        do {
          if (isErrorCount > 50) {
            break
          }
          // eslint-disable-next-line no-loop-func
          findMenu = this.flatDeepTreeData.find(menu => menu.id === menuId)
          if (findMenu && findMenu.parentId) {
            parentIdSet.add(findMenu.parentId)
            menuId = findMenu.parentId
          }
          isErrorCount++
        } while (findMenu && findMenu.parentId)
      })
      const checkMenuParentIdList = Array.from(parentIdSet)
      if (isErrorCount >= 50) {
        return []
      } else {
        return checkMenuParentIdList
      }
    },
    /** TODO 获取勾选节点以及获取对应资源
     * 1、获取勾选节点
     * 2、计算出资源树的节点
     * 3、渲染资源树
     *  */
    checkChange(e) {
      if (!e) return
      const menuIdSet = new Set()

      if (e.checked) {
        e = e.checked
      }
      const menus = []
      const defaultCheckedKeys = []
      // 获取勾选节点
      if (e && e.length > 0) {
        e.forEach(item => {
          if (item !== -1) {
            menuIdSet.add(item)
            defaultCheckedKeys.push(item)
          }
        })
        const parentIdList = this.checkMenuParentIdHandle(e)
        parentIdList.forEach(item => {
          menuIdSet.add(item)
        })
        Array.from(menuIdSet).forEach(item => {
          // defaultCheckedKeys.push(item)
          menus.push({
            menuId: item,
            resources: ''
          })
        })
        this.defaultCheckedKeys = defaultCheckedKeys
        this.submitData.menus = menus
      }

      this.menu = {}
      if (e.length === 0) {
        this.treeSelectData = []
        return
      }
      let checkMenuTreeData = []
      // 将menuId数组转换为对应的menu数组
      menus.forEach(menu => {
        const findMenu = this.flatDeepTreeData.find(data => data.id === menu.menuId)
        if (!findMenu) return
        checkMenuTreeData.push(findMenu)
      })
      checkMenuTreeData = checkMenuTreeData.map(item => {
        // 复制数组元素，并且复制资源和清空children
        const obj = Object.assign({}, item)
        if (this.submitData.menuList) {
          const findMenu = this.submitData.menuList.find(menu => menu.menuId === obj.id)
          if (findMenu && findMenu.resources) {
            obj.menuResources = findMenu.resources.replace(/^,/, '').replace(/,$/, '').split(',').map(item => Number(item))
          }
          obj.children = []
        }
        return obj
      })
      const treeSelectData = []
      // 遍历选中的数组，按照parentId来拼接为树形
      checkMenuTreeData.forEach(menu => {
        if (menu.parentId) {
          const parentMenu = checkMenuTreeData.find(item => item.id === menu.parentId)
          if (parentMenu) {
            parentMenu.children.push(menu)
          }
        } else {
          treeSelectData.push(menu)
        }
      })
      this.checkMenuTreeData = checkMenuTreeData
      this.treeSelectData = treeSelectData
    },
    resetInstance() {
      this.$xyConfirm('确认是否重置实例，重置后修改的数据将无法恢复？', () => {
        this.isResetInstance = true
        this.resetData(() => {
          this.isResetInstance = false
        })
      })
    },

    initMenuResource(menus) {
      if (!menus || menus.length === 0) {
        return
      }
      menus.forEach(menu => {
        menu.resources = menu.resources || ''
        menu.scopedSlots = { title: 'slotsTitle' }
        menu.resources = menu.resources.replace(/^,/, '').replace(/,$/, '')
        if (menu.resources) {
          menu.resources = menu.resources.split(',')
          for (let i = 0; i < menu.resources.length; i++) {
            menu.resources[i] = Number(menu.resources[i])
          }
        } else {
          menu.resources = []
        }
        this.$set(menu, 'menuResources', [])
        this.initMenuResource(menu.children)
      })
    },
    setLevel(data, level, sort) {
      level = level || 0
      sort = sort || 0
      if (!data || data.length === 0) {
        return sort
      }
      data.forEach(item => {
        item.level = level
        if (level <= 1) {
          item.type = 3
        } else if (!item.type) {
          item.type = 1
        }
        item.appId = this.appId
        item.sort = sort++
        item.children = item.children || []
        sort = this.setLevel(item.children, level + 1, sort)
      })
      return sort
    },
    async resetData(cb) {
      if (this.submitData.menuList) {
        this.submitData.menuList.forEach(item => {
          item.resources = item.oldResources || ''
        })
      }
      this.menu = {}
      this.treeData = []
      let data = await this.$store.dispatch('auth/authMenuList', {
        appId: this.appId
      })
      data = data || []
      this.treeData = data
      this.flatDeepTreeData = this.$utils.flatDeep(data || [], 'children')
      this.treeData = [{
        id: -1,
        name: '全部菜单',
        children: this.treeData
      }]
      this.initMenuResource(this.treeData)
      this.setLevel(this.treeData)
      this.treeSelectData = []
      if (!this.appUserInstanceId) {
        this.submitData = this.$options.data().submitData
      } else {
        this.setCheckedKeys()
      }
      this.getMenuResources(cb)
      this.isInit = true
    },
    setCheckedKeys() {
      this.$nextTick(() => {
        const ids = []
        if (this.submitData.menuList) {
          this.submitData.menuList.forEach(item => {
            // 过滤掉有子节点的
            const findMenu = this.flatDeepTreeData.find(data => data.id === item.menuId)
            if (!findMenu || (findMenu.children && findMenu.children.length > 0)) return
            ids.push(item.menuId)
          })
        }
        // TODO 默认选中节点
        // this.$refs.tree.setCheckedKeys(ids)
        this.defaultCheckedKeys = ids
        // 默认选中节点
        this.checkChange(ids)
        this.$nextTick(() => {
          if (this.submitData.menuList) {
            this.submitData.menuList.forEach(item => {
              item.oldResources = item.resources
              item.resources = ''
            })
          }
        })
      })
    },
    /**
     * 菜单数据格式化
     * [{menuId: '', resources: ''}, {menuId: '', resources: ''}]
     */
    _getMenus(menus, data) {
      if (!data || data.length === 0) {
        return
      }
      data.forEach(item => {
        let r = item.menuResources || []
        r = r.join(',')
        if (r) {
          r = ',' + r + ','
        }
        menus.push({
          menuId: item.id,
          resources: r
        })
        this._getMenus(menus, item.children)
      })
    },
    async save() {
      this.isSave = true
      const menus = []
      this._getMenus(menus, this.treeSelectData)
      this.submitData.menus = menus
      this.submitData.userId = this.id
      this.submitData.appInstanceId = this.appInstanceId
      this.submitData.id = this.$utils.getParam('appUserInstanceId')
      // const menus = []
      // this._getMenus(menus, this.treeSelectData)
      // this.submitData.menus = menus
      await this.$store.dispatch('auth/instanceSave', this.submitData)
      this.isSave = false
      this.$toPage('/auth-app-user-instance/list', {
        userId: this.id
      })
    },
    async getMenuResources(cb) {
      const data = await this.$store.dispatch('auth/authResourcesListForApp', {
        appInstanceId: this.appInstanceId
      })
      const resource = data || []
      this.menuResource = {}
      resource.forEach(item => {
        item.key = Number(item.id)
        this.menuResource[item.id] = item
      })
      cb && cb()
    },
    resetForm() {
      this.$store.dispatch('auth/authAppInstanceList', {
        page: 1,
        size: 65535
      })
    }
  }
}
</script>

<style lang="scss">
.lineThrough {
  text-decoration: line-through;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 10px;
}
.l-transfer__buttons {
  padding: 0 6px;
}
.l-transfer__button {
  border-radius: 50%;
  font-size: 0;
  min-width: 0;
  padding: 0;
  height: 34px;
  width: 34px;
  line-height: 34px;
}
.l-select-dropdown {
  max-width: 217px;
}

.c-menu-tree {
  display: inline-block;
  width: 30%;
  vertical-align: top;
}
.c-menu-tree_setting {
  display: inline-block;
  width: 30%;
  vertical-align:top;
  box-sizing: border-box;
  padding-left: 20px;
}
</style>
